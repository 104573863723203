// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
//$body-bg: #000;
//$body-color: #111;
$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
$primary: #2A959D !default;
$secondary: #E8C29D !default;
$success: #2A959D !default;
$dark: #003F47 !default;
$navbar-brand-height: 5rem;


